@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  
  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  composes: h3 from global;
  white-space: nowrap;
  font-size: 32px;
}

.sortyByWrapper {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  width: 100%;
  max-width: 355px;
}

.sortyBy {
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 1;
  padding: 8px 0 8px 0;
  flex-shrink: 0;
  margin-right: 20px;
}
