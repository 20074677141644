@import '../../../styles/customMediaQueries.css';

.root {
  display: none;
  
  @media (--viewportMedium) {
    display: inline-flex;
    align-items: center;
  }
}

.counterText {
  flex-shrink: 0;
  margin-right: 24px;
  font-size: 14px;
  color: var(--customGray);
}

.counterDropdown {
  padding: 2px 37px 2px 10px;
  font-size: 14px;
  line-height: 32px;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2802 6.46655L8.93355 10.8132C8.42021 11.3266 7.58021 11.3266 7.06688 10.8132L2.72021 6.46655' stroke='%23201F22' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-size: auto;
  background-position: top 10px right 10px;
}
