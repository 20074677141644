@import '../../../styles/customMediaQueries.css';

.noSearchResults {
  /* composes: h4 from global; */

  /* Reserves 48px of vertical space */
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 11px;
    margin-bottom: 11px;
  }
}

.noSearchResultsButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.createListingLink,
.resetAllFiltersButton {
  composes: buttonDefault from global;
  max-width: 140px;
  width: 100%;
  padding: 16px 10px;
  min-height: 20px;
  font-size: 14px;
  line-height: 1;
  border-radius: 4px;

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--colorWhite);
  }
}

