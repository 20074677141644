@import '../../../styles/customMediaQueries.css';

.sortBy {
  
  @media (--viewportMedium) {
    margin-right: 9px;
  }
}

.sortByDesktop {
  margin-left: auto;
  width: 100%;
}

.sortByMenuLabel {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 48px;
  min-height: 35px;

  padding: 0 20px;
  margin: 0 0 24px;
  
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font-weight: var(--fontWeightNormal);
  font-size: 16px;
  line-height: 1px;
  
  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  border: 1px solid #ECECEC;
  border-radius: 8px;

  background-color: var(--colorWhite);

  color: var(--customGray);

  @media (--viewportMedium) {
    margin: 0;
    justify-content: space-between;
  }

  &:focus,
  &:hover {
    outline: none;
    box-shadow: none;
    text-decoration: none;
    border-color: var(--colorGrey300);
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  &::before {
    content: "Sort by:";
    display: inline-block;
    margin-right: 4px;
    color: var(--colorGrey700);

    @media (--viewportMedium) {
      display: none;
    }
  }
}
